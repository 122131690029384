.InlineLink {
    display: inline-block;
    color: rgb(220, 70, 50);
    text-decoration: none;
}

.InlineLink:hover {
    cursor: pointer;
}

.InlineLink:after {
    display: block;
    background: linear-gradient(
            to right,
            rgb(105, 0, 130),
            rgb(180, 80, 115),
            rgb(200, 55, 85),
            rgb(240, 100, 10),
            rgb(230,145,5)
    );
    content: '';
    width: 0;
    height: 2px;
    transition: 0.25s ease-out;
}

.InlineLink:hover:after {
    width: 100%;
}
