.CompetencyBar {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 1em;
    border: solid white;
    border-width: 0 1px;
    background-image: linear-gradient(white, white);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position-y: center;
}

.Competence {
    background-color: white;
    height: 0.33em;
    transition: width 1s ease-out;
}
