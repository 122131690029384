.Header {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: scroll;
}

.HeaderText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    color: white;
    flex-grow: 1;
}

.FirstName {
    font-size: 3em;
    font-weight: 300;
}

.LastName {
    font-size: 3em;
    font-weight: 300;
}

.Title {
    margin-top: 20px;
    font-size: 1.5em;
    font-weight: 100;
    font-variant-caps: all-small-caps;
}

.Caret {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 50%;
    align-self: center;
    color: white;
    font-size: 3em;
    transition: all 0.25s ease-out;
}

.Caret:hover {
    cursor: pointer;
    border: 1px solid white;
    background: rgba(255, 255, 255, 0.1);
    transform: scale(1.25);
}

@media (min-width: 600px) {
    .FirstName {
        font-size: 5em;
    }

    .LastName {
        font-size: 5em;
    }

    .Title {
        font-size: 2em;
    }
}

@media (min-width: 1200px) {
    .HeaderText {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .FirstName {
        font-size: 6em;
    }

    .FirstName::after {
        content: " ";
        white-space: pre;
    }

    .LastName {
        font-size: 6em;
    }

    .Title {
        font-size: 2.5em;
        flex-basis: 100%;
    }
}
