.Portfolio {
    max-width: 1000px;
    margin: 0 auto;
    background: rgba(0, 0, 0, .9);
}

.PortfolioSections {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 5px 10px;
    justify-content: center;
    align-items: center;
    padding: 50px 20px 80px;
    color: white;
    max-width: 800px;
    margin: 0 auto;
}

@media (min-width: 1000px) {
    .Portfolio {
        border-radius: 50px;
        box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.5);
    }
}
