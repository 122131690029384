.ContactMethod {
    display: flex;
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid rgb(220, 70, 50);
    background-color: white;
    transition: background-color  0.25s ease-out,
                transform 0.25s ease-out;
}

.Icon {
    color: rgb(220, 70, 50);
    font-size: 2em;
}

.ContactMethod:hover {
    cursor: pointer;
    background-color: rgb(220, 70, 50);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    transform: scale(1.125);
}

.ContactMethod:hover .Icon {
    color: white;
}
