.Contact {
    margin: 0 auto;
    padding: 50px 20px;
    max-width: 600px;
}

.ContactHeader {
    margin: 10px 0;
    font-size: 2em;
    text-align: center;
}

.ContactMethods {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
