.SectionName {
    margin: 50px 0 15px 0;
    grid-column-start: span 3;
    text-align: center;
    font-size: 1.25em;
}

.Separator {
    display: block;
    grid-column-start: span 3;
    height: 10px;
}
