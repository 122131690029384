.About {
    overflow-x: hidden;
}

.Content {
    margin: 0 auto;
    padding: 50px 20px;
    max-width: 600px;
}

.FloatingHeadshot {
    display: block;
    margin: 10px auto;
    width: 175px;
    max-width: 50%;
    text-align: center;
}

.HelloHeader {
    margin: 10px 0;
    font-size: 2em;
    text-align: center;
}

.About p {
    font-size: 1.1em;
}